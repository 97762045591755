import { useEffect } from "react";
import Image from "next/image";
import Link from "next/link";

import "venobox/dist/venobox.min.css";
import separator from "../../public/images/separator/separator-top.svg";

const Home = () => {
  useEffect(() => {
    import("venobox/dist/venobox.min.js").then((venobox) => {
      new venobox.default({
        selector: ".popup-video",
        maxWidth: window.innerWidth >= 992 ? "50%" : "100%",
      });
    });
  }, []);
  return (
    <>
      <div
        className="slider-area slider-style-1 variation-default slider-bg-image bg-banner1"
        data-black-overlay="1"
      >
        <div className="content-video">
          <video muted loop autoPlay>
            <source src="video/websitefrontpage.mp4" type="video/mp4"/>
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="content-overlay"></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="inner text-center mt--150 mb--100">
                <h1 className="title display-one">
                  Unleash the Potential Of <br />
                  <span className="theme-gradient">Game Hosting</span> With
                  <br />
                  <span className="color-off">Cutting-Edge Hosting</span>
                </h1>
                <p className="b1 desc-text content-landing">
                  Top-Tier hosting provider based in Australia.
                </p>
                <div className="button-group">
                  <Link
                    className="btn-default bg-light-gradient btn-large"
                    href="https://payments.roninservers.com/store/game-hosting"
                  >
                    <div className="has-bg-light"></div>
                    <span>Choose your game server</span>
                  </Link>
                </div>
                <p className="color-gray mt--5 content-gray">We offer hosting in United States, Europe and Asia-Pacific.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="chatenai-separator has-position-bottom">
          <Image className="w-100" src={separator} alt="" />
        </div>
      </div>
    </>
  );
};

export default Home;
